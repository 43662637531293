var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"height":"100%"}},[_c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"grid-list-xl":"","fluid":"","column":""}},[(_vm.firstMessage)?_c('v-card',{staticStyle:{"flex-grow":"0"},attrs:{"color":"blue"}},[_c('v-card-text',[_c('v-icon',{staticClass:"pa-2"},[_vm._v("mdi-information-outline")]),_vm._v(" Hey, it doesn't look like you two have spoken before! Say hi and solve his problem. ")],1)],1):_vm._e(),_c('v-card',[_c('v-card-title',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('label',[_vm._v(" Multiple Message ")]),_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#482684"}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}])},[_c('v-list',_vm._l((_vm.menus),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"change":function($event){return _vm.onSelectedMenu(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.users,"filled":"","chips":"","color":"blue-grey lighten-2","label":"Select","item-text":"name","item-value":"name","multiple":"","hide-details":"","solo":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeFromGroup(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":data.item.image ? data.item.image : _vm.placeholderImage}})],1),_vm._v(" "+_vm._s(data.name ? data.name : data.item.first_name + (data.item.last_name ? " " + data.item.last_name : ""))+" ")],1)]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.image ? data.item.image : _vm.placeholderImage}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                    data.item.name
                      ? data.item.name
                      : data.item.first_name +
                        (data.item.last_name ? ' ' + data.item.last_name : '')
                  )}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.group)}})],1)]]}}]),model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c('div',{ref:"chatContent",staticStyle:{"height":"100%","padding-top":"20px","padding-bottom":"90px","flex-grow":"1","overflow-y":"scroll"},attrs:{"id":"chat-content"}},_vm._l((_vm.messages),function(msg,index){return _c('v-layout',{key:index,ref:index == _vm.messages.length - 1 ? 'lastMessage' : '',refInFor:true,staticClass:"mx-3",attrs:{"row":"","align-start":msg.sender_type === 'Admin',"align-end":msg.sender_type !== 'Admin',"align-content-start":msg.sender_type === 'Admin',"align-content-end":msg.sender_type !== 'Admin'}},[_c('v-flex',{attrs:{"xs6":"","sm4":"","offset-xs6":msg.sender_type === 'Admin',"offset-sm8":msg.sender_type === 'Admin'}},[_c('v-card',{staticClass:"msg",attrs:{"color":msg.sender_type !== 'Admin' ? 'white' : 'blue'}},[_c('v-card-text',{class:msg.sender_type !== 'Admin' ? 'black--text' : 'white--text'},[_vm._v(" "+_vm._s(msg.text)+" ")])],1)],1)],1)}),1)],1),_c('v-footer',{attrs:{"absolute":""}},[_c('v-layout',{staticClass:"pa-4",attrs:{"row":"","align-center":""}},[_c('v-flex',{staticStyle:{"flex-grow":"1","max-width":"100%"}},[_c('v-textarea',{attrs:{"label":"Type =a message...","rows":"1","auto-grow":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessageButton()}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.sendMessageButton()}}},'v-btn',attrs,false),on),[_c('span',[_vm._v("send")]),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-send")])],1)]}}])},[_c('span',[_vm._v("Enter to send")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }