<template>
  <v-layout style="height: 100%">
    <v-container
      grid-list-xl
      fluid
      column
      style="height: 100%; display: flex; flex-direction: column;"
    >
      <v-card color="blue" v-if="firstMessage" style="flex-grow:0;">
        <v-card-text>
          <v-icon class="pa-2">mdi-information-outline</v-icon>
          Hey, it doesn't look like you two have spoken before! Say hi and solve
          his problem.
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title style="display: flex; justify-content: space-between;">
          <label>
            Multiple Message
          </label>
          <v-menu offset-y rounded="lg">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon color="#482684">
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menus"
                v-on:change="onSelectedMenu(index)"
                :key="index"
                link
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="group"
            :items="users"
            filled
            chips
            color="blue-grey lighten-2"
            label="Select"
            item-text="name"
            item-value="name"
            multiple
            hide-details
            solo
            clearable
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="removeFromGroup(data.item)"
              >
                <v-avatar left>
                  <v-img
                    :src="data.item.image ? data.item.image : placeholderImage"
                  ></v-img>
                </v-avatar>
                {{
                  data.name
                    ? data.name
                    : data.item.first_name +
                      (data.item.last_name ? " " + data.item.last_name : "")
                }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img
                    :src="data.item.image ? data.item.image : placeholderImage"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="
                      data.item.name
                        ? data.item.name
                        : data.item.first_name +
                          (data.item.last_name ? ' ' + data.item.last_name : '')
                    "
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.group"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
      <div
        id="chat-content"
        ref="chatContent"
        style="height: 100%;padding-top:20px; padding-bottom: 90px;flex-grow:1; overflow-y: scroll;"
      >
        <v-layout
          v-for="(msg, index) in messages"
          :key="index"
          row
          :align-start="msg.sender_type === 'Admin'"
          :align-end="msg.sender_type !== 'Admin'"
          :align-content-start="msg.sender_type === 'Admin'"
          :align-content-end="msg.sender_type !== 'Admin'"
          class="mx-3"
          :ref="index == messages.length - 1 ? 'lastMessage' : ''"
        >
          <v-flex
            xs6
            sm4
            :offset-xs6="msg.sender_type === 'Admin'"
            :offset-sm8="msg.sender_type === 'Admin'"
          >
            <v-card
              class="msg"
              :color="msg.sender_type !== 'Admin' ? 'white' : 'blue'"
            >
              <v-card-text
                :class="
                  msg.sender_type !== 'Admin' ? 'black--text' : 'white--text'
                "
              >
                {{ msg.text }}
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
    <v-footer absolute>
      <v-layout row align-center class="pa-4">
        <v-flex style="flex-grow:1; max-width: 100%">
          <v-textarea
            v-model="message"
            @keyup.enter="sendMessageButton()"
            label="Type =a message..."
            rows="1"
            auto-grow
          />
        </v-flex>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue"
              text
              @click="sendMessageButton()"
              class="ml-5"
              v-bind="attrs"
              v-on="on"
            >
              <span>send</span>
              <v-icon class="ml-2">mdi-send</v-icon>
            </v-btn>
          </template>
          <span>Enter to send</span>
        </v-tooltip>
      </v-layout>
    </v-footer>
  </v-layout>
</template>
<script>
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      firstMessage: false,
      messages: [],
      message: "",
      conversation: null,
      menus: [{ title: "Clear Message" }],
      deleteDialg: false,
      group: [],
      users: [],
      placeholderImage: require("@/assets/default.jpg"),
    };
  },
  methods: {
    ...mapActions({
      getChat: "chat/getChat",
      getConversation: "chat/getConversation",
      sendMessage: "chat/sendMessage",
      getPartners: "partner/fetchAllOrganizations",
      getClients: "client/fetchAllClients",
    }),
    sendMessageButton() {
      if (this.message) {
        this.messages.push({ sender_type: "Admin", text: this.message });
        var tempMessage = this.message;
        this.message = "";
        setTimeout(() => {
          if (this.$refs.lastMessage[0]) {
            this.$vuetify.goTo(
              this.$refs.lastMessage[0],
              {
                container: this.$refs.chatContent,
              },
              300
            );
          }
        });
        this.group.map((u) => {
          this.sendMessage({
            user_type: u.user_type,
            user_id: u._id,
            content_type: "text",
            text: tempMessage,
          }).then(() => {});
        });
      }
    },
    onScroll(e) {
      console.log(e.target);
    },
    getAllUers() {
      this.loading = true;
      this.users = [];
      this.users.push({ header: "Partner" });
      this.getPartners()
        .then((data) => {
          data.map((d) => {
            this.users.push({ ...d, user_type: "Partner" });
          });
          this.users.push({ divider: true });
          this.loading = false;
          this.getClients()
            .then((clients) => {
              this.users.push({ header: "Client" });
              clients.map((d) => {
                this.users.push({ ...d, user_type: "Client" });
              });
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    },
    removeFromGroup(user) {
      console.log(user, this.group);
      this.group = this.group.filter(
        (u) => u._id != user._id || u.user_type != user.user_type
      );
    },

    onSelectedMenu(index) {
      if (this.menus[index].title == "Clear Message") {
        this.messages = [];
      }
    },
    onConfirmDeleteConversation() {
      this.deleteDialg = false;
      this.deleteConversation({ _id: this.conversation._id });
    },
    onCloseConfirmDelete() {
      this.deleteDialg = false;
    },
  },
  created() {
    this.getAllUers();
  },
  watch: {
    "$route.params.id": function() {
      this.onRefresh();
    },
  },
};
</script>
<style></style>
